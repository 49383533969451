if ($('#contact').length) {
	// Google Recaptcha v3
	grecaptcha.ready(function () {
		$('form.mod-recaptcha').each(function () {
			var object = $(this);
			grecaptcha.execute('6LfV9tolAAAAAHyuPhF-quuGRjCTDNpCZc2jR6wl', { action: 'homepage' }).then(function (token) {
				$('<input>').attr({
					type: 'hidden',
					name: 'g-recaptcha-response',
					value: token
				}).appendTo(object)
			});
		});
	});
}

/**
 * Footer terms of business dropdown
 */

$('.list-footer-links li.mod-dropdown span').click(function (e) {
	e.preventDefault();

	$(this).next('.list-terms').toggleClass('mod-active');

});


/**
 * Homepage hero scroll effect on text
 */

$(window).on('scroll load scroll', function () {
	if ($(window).width() > 1024) {
		const hero = $('body#home .section-hero .sub-content'),
			heroAlt = $('body:not(#home) .section-hero .sub-content');

		// Parallax effect on homepage hero
		hero.css({
			"top": Math.max(19 + 0.04 * window.scrollY, 0) + "%",
			"opacity": 1 - $(window).scrollTop() / 750
		});

		heroAlt.css({
			"top": Math.max(50 - 0.06 * window.scrollY, 0) + "%",
			"opacity": 1 - $(window).scrollTop() / 650
		});
	}
});

// Mobile Nav
$('.mob-hamburger').click(function (e) {
	e.preventDefault();

	$(this).toggleClass('mod-active');
	$('body').toggleClass('no-scroll');
	$('#nav').toggleClass('mod-active');
	$('.logo').fadeToggle();
	$('.list-nav li a.mod-dropdown').removeClass('mod-active');
	$('ul.sub-dropdown').removeClass('mod-active');
});

// Prevent dropdown link being clicked on
$('.list-nav li a.mod-dropdown').click(function (ev) {
	ev.preventDefault();

	$(this).toggleClass('mod-active');
	$(this).next('ul.sub-dropdown').toggleClass('mod-active');
});

// Show/hide cookie policy
$("body").on("click", ".section-cookies .btn-accept", function (e) {
	e.preventDefault();

	// If no validation errors
	$.ajax({
		method: "POST",
		url: "api/cookies/",
	}).done(function (response) {
		$(".section-cookies").slideToggle();
	});
});

$(".section-cookies .btn-decline").on("click", (ev) => {
	ev.preventDefault();

	$(".section-cookies").slideToggle();
});

// Pops out share modal window
$(".social-share").click(function (e) {
	// Prevents default action
	e.preventDefault();
	// Runs window popup function
	windowPopup($(this).attr("href"), 500, 300);
});

// Ajax contact form processing
$('#contact form').submit(function (e) {
	e.preventDefault();

	// if honeypot field is filled in by spambot, do not submit
	if ($('input#Form_Email').val().length != 0) {
		return false;
	}

	$.ajax({
		method: 'POST',
		url: 'process/contact/',
		data: $(this).serialize()
	}).done(function (response) {

		// If no errors
		if (response == 'success') {
			// Reset form completely
			$('#contact form .form-element').removeClass('mod-filled');
			$('#contact form').hide().trigger('reset');

			// Show thank you message
			$('#contact .wrap-thanks').fadeIn();

			// Remove any errors that may still be there
			$('#contact form .sub-errors').html('');

			// Hide thank you message after 10 seconds and return to form
			setTimeout(function () {
				$('#contact form').fadeIn();
				$('#contact .wrap-thanks').hide();
			}, 5000);

		}
		else {
			$('#contact form .sub-errors').fadeIn().html(response);
		}
	});
});

// Ajax contact form processing
$('#quote form').submit(function (e) {
	e.preventDefault();

	// if honeypot field is filled in by spambot, do not submit
	// if ($('input#Form_Email').val().length != 0) {
	// 	return false;
	// }

	$.ajax({
		method: 'POST',
		url: 'process/quote/',
		data: new FormData(this),
		processData: false,
		contentType: false
	}).done(function (response) {

		// If no errors
		if (response == 'success') {
			$('.section-quote').hide();
			$('.section-thanks').show();
		}
		else {
			$('#quote form .sub-errors').fadeIn().html(response);
		}
	});
});

// Add class to inputs when clicked on add effect

if ($('form').length) {
	$('form input, form textarea').focus(function () {
		$(this).parent().addClass('mod-filled');

	}).blur(function () {
		if ($(this).val()) {
			$(this).parent().addClass('mod-filled');
		} else {
			$(this).parent().removeClass('mod-filled');
		}
	});
}


// Google Maps

// If div with ID of map exists then run the function
if ($('#map').length) {
	function createMap(el, lat, long, zoom, marker) {
		// Set a center point of the map, can be re-evaluated using `centerMap()`
		const center = { lat: lat, lng: long };

		const mapOptions = {
			zoom: zoom,
			center: center,
			fullscreenControl: false,
		};

		// Create a map instance.
		const melonMap = new MelonMap(document.getElementById(el), mapOptions);

		// Marker locations [name, lat, long, zindex]
		const markerLocation = [
			['Beehive Coils Ltd - Milton Keynes', lat, long, 1],
		];

		if (marker == true) {
			markerLocation.forEach((markerLocation) => {
				const title = markerLocation[0];

				const location = {
					lat: parseFloat(markerLocation[1]),
					lng: parseFloat(markerLocation[2])
				}

				melonMap.addMarker(location, title);
			});
		}

		// Add a map style
		melonMap.setMapStyle(
			[{ "featureType": "all", "elementType": "geometry.fill", "stylers": [{ "weight": "2.00" }] }, { "featureType": "all", "elementType": "geometry.stroke", "stylers": [{ "color": "#9c9c9c" }] }, { "featureType": "all", "elementType": "labels.text", "stylers": [{ "visibility": "on" }] }, { "featureType": "landscape", "elementType": "all", "stylers": [{ "color": "#f2f2f2" }] }, { "featureType": "landscape", "elementType": "geometry.fill", "stylers": [{ "color": "#ffffff" }] }, { "featureType": "landscape.man_made", "elementType": "geometry.fill", "stylers": [{ "color": "#ffffff" }] }, { "featureType": "poi", "elementType": "all", "stylers": [{ "visibility": "off" }] }, { "featureType": "road", "elementType": "all", "stylers": [{ "saturation": -100 }, { "lightness": 45 }] }, { "featureType": "road", "elementType": "geometry.fill", "stylers": [{ "color": "#eeeeee" }] }, { "featureType": "road", "elementType": "labels.text.fill", "stylers": [{ "color": "#7b7b7b" }] }, { "featureType": "road", "elementType": "labels.text.stroke", "stylers": [{ "color": "#ffffff" }] }, { "featureType": "road.highway", "elementType": "all", "stylers": [{ "visibility": "simplified" }] }, { "featureType": "road.arterial", "elementType": "labels.icon", "stylers": [{ "visibility": "off" }] }, { "featureType": "transit", "elementType": "all", "stylers": [{ "visibility": "off" }] }, { "featureType": "water", "elementType": "all", "stylers": [{ "color": "#46bcec" }, { "visibility": "on" }] }, { "featureType": "water", "elementType": "geometry.fill", "stylers": [{ "color": "#c8d7d4" }] }, { "featureType": "water", "elementType": "labels.text.fill", "stylers": [{ "color": "#070707" }] }, { "featureType": "water", "elementType": "labels.text.stroke", "stylers": [{ "color": "#ffffff" }] }],
			{ name: 'Styled Map' }
		);
	}

	// If the contact page then create the map with the following coordiantes
	if ($('#contact').length) {
		createMap('map', 52.25883018333879, 0.3878385624168442, 11, true);
	}
}

$('ul.list-testimonials').slick({
	arrows: true,
	autoplay: true,
	autoplaySpeed: 5000,
	adaptiveHeight: true,
	dots: false,
	fade: false,
	infinite: true,
	nextArrow: $('.sub-testimonials-nav .sub-next'),
	prevArrow: $('.sub-testimonials-nav .sub-prev'),
	slidesToScroll: 1,
	slidesToShow: 1
});

if ($('.section-testimonials-block')) {
	$('.list-testimonials-block').slick({
		arrows: false,
		autoplay: true,
		autoplaySpeed: 4500,
		draggable: true,
		dots: true,
		fade: true,
		infinite: true,
		slidesToScroll: 1,
		slidesToShow: 1
	});
}

if ($('#blog-post').length) {
	const newsStoryCarousel = $('.carousel-images-wrap');

	newsStoryCarousel.slick({
		autoplay: true,
		autoplaySpeed: 5000,
		dots: true,
		arrows: false
	});
}

if ($('#history')) {
	const gallerySlide = $('ul.list-gallery');

	$('.modal-gallery .modal-close').on('click', function (ev) {
		gallerySlide.slick('slickGoTo', 0);
	});

	gallerySlide.slick({
		arrows: true,
		autoplay: false,
		dots: false,
		fade: true,
		infinite: true,
		nextArrow: $('.sub-gallery-nav .sub-next'),
		prevArrow: $('.sub-gallery-nav .sub-prev'),
		slidesToScroll: 1,
		slidesToShow: 1
	});
}

if ($('#products')) {

	const modalFastTrack = document.getElementById('modalFastTrack');

	$('.section-main-copy .sub-copy a[href*="youtube"]').on('click', function (event) {
		event.preventDefault();

		// Add open class to modal to reveal
		modalFastTrack.classList.add("modal-open");

		if ($('#modalFastTrack.modal').find('iframe')) {
			$('#modalFastTrack.modal').find('iframe').attr('src', $('#modalFastTrack.modal').data('iframe'));
		}

		// Make behind body content unscrollable
		$('body').addClass('no-scroll');
		$(this).addClass('active');


		// Close modal when clicking on the 'X' buttonmodal
		$('.modal .modal-close').click(function () {
			// Remove open class to close
			modalFastTrack.classList.remove("modal-open");

			// Make behind body content scrollable again
			$('body').removeClass('no-scroll');

			$('#modalFastTrack.modal').find('iframe').attr('src', '');
		});

		// Close modal when clicked outside of actual modal
		window.onclick = function (event) {
			if (event.target == modalFastTrack) {
				modalFastTrack.classList.remove("modal-open");

				$('body').removeClass('no-scroll');

				$('#modalFastTrack.modal').find('iframe').attr('src', '');
			}
		}
	});

	$('.list-carousel').slick({
		arrows: false,
		autoplay: true,
		autoplaySpeed: 4500,
		draggable: true,
		dots: false,
		fade: true,
		infinite: true,
		slidesToScroll: 1,
		slidesToShow: 1
	});
}

if ($('#quote')) {
	$('.section-quote .sub-info .btn-quote').on('click', function (event) {
		event.preventDefault();

		const type = $('[name="page-type"]:checked')[0].value || 'replacement';

		window.location.href += type + "/";
	});

	$(".btn_upload").on('click', function (e) {
		e.preventDefault();
		$('input[type="file"]').click();
	});

	$("input[type='file']").on('change', function () {
		const files = Array.from(document.querySelector(".sub-upload input[type='file']").files);

		if (files.length < 1) {
			return;
		}

		$('#list-files').empty();


		files.forEach(function (file) {
			const name = file.name;

			const li = "<li>" + name + "</li>";

			$('#list-files').append(li);
		});
	});
}


// Function for popout window
function windowPopup(url, width, height) {
	// Calculate the position for the popup so it’s centered on the screen.
	const left = screen.width / 2 - width / 2;
	const top = screen.height / 2 - height / 2;

	// opens a window with the given url & set position from above
	window.open(
		url,
		"",
		"menubar=no,toolbar=no,resizable=yes,scrollbars=yes,width=" +
		width +
		",height=" +
		height +
		",top=" +
		top +
		",left=" +
		left
	);
}

// Pops out gallery image
const modal = document.getElementById('modal');

if ($('.mod-video-block, .section-gallery, #gallery').length) {
	$('.mod-video-block .btn-modal, .section-gallery .btn-modal, #gallery .list-gallery-alt li').click(function (e) {
		// Prevent default action
		e.preventDefault();

		// Add open class to modal to reveal
		modal.classList.add("modal-open");

		if ($('.modal').find('iframe')) {
			$('.modal').find('iframe').attr('src', $('.modal').data('iframe'));
		}

		if ($('#gallery').length) {
			var index = $(this).data('index');
			$('.list-gallery').slick('slickGoTo', index);
		}

		// Make behind body content unscrollable
		$('body').addClass('no-scroll');
		$(this).addClass('active');

	});

	// Close modal when clicking on the 'X' buttonmodal
	$('#modal.modal .modal-close').click(function () {
		// Remove open class to close
		modal.classList.remove("modal-open");

		// Make behind body content scrollable again
		$('body').removeClass('no-scroll');

		$('#modal.modal').find('iframe').attr('src', '');
	});

	// Close modal when clicked outside of actual modal
	window.onclick = function (event) {
		if (event.target == modal) {
			modal.classList.remove("modal-open");
			$('body').removeClass('no-scroll');

			$('#modal.modal').find('iframe').attr('src', '');
		}
	}
}
