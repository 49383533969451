var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var MelonMap = /** @class */ (function () {
    function MelonMap(element, settings) {
        /**
         * Default settings used to control the application.
         */
        this.defaultMapSettings = {
            zoom: 10,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            mapTypeControl: false,
            streetViewControl: false,
            panControl: false,
            draggable: true,
            scrollwheel: false
        };
        /** Image of the marker */
        this.markerImage = '';
        /** Shape of the marker image */
        this.markerShape = {
            coords: [34, 71, 28, 60, 1, 60, 1, 1, 3, 0, 68, 0, 68, 60, 41, 60],
            type: 'poly'
        };
        this.map = new google.maps.Map(element, __assign({}, this.defaultMapSettings, settings));
        this.bounds = new google.maps.LatLngBounds();
        this.setMarkerImage('assets/images/map-pin.png');
    }
    /**
     *
     * @param markerImage Path to the marker image
     * @param size Size of the image, [x, y]
     * @param point Placement point of the image, [x, y]
     */
    MelonMap.prototype.setMarkerImage = function (markerImage, size, point) {
        if (size === void 0) { size = [78, 80]; }
        if (point === void 0) { point = [0, 0]; }
        var _a, _b;
        this.markerImage = new google.maps.MarkerImage(markerImage, new ((_a = google.maps.Size).bind.apply(_a, [void 0].concat(size)))(), new ((_b = google.maps.Point).bind.apply(_b, [void 0].concat(point)))());
    };
    /**
     *
     * @param style Style array of the map
     * @param styleMeta Style name of the map.
     */
    MelonMap.prototype.setMapStyle = function (style, styleMeta) {
        // Map styling
        var styledMapType = new google.maps.StyledMapType(style, styleMeta);
        // associating map with styling
        this.map.mapTypes.set('styled_map', styledMapType);
        this.map.setMapTypeId('styled_map');
    };
    /**
     * Add a new marker to the map.
     * @param location Coordinate location
     * @param title Title of the pin
     * @param zIndex Depth placement of the pin
     */
    MelonMap.prototype.addMarker = function (location, title, zIndex) {
        if (zIndex === void 0) { zIndex = 0; }
        if (typeof location.lat === 'string' && typeof location.lng === 'string') {
            location.lat = parseFloat(location.lat);
            location.lng = parseFloat(location.lng);
        }
        var marker = new google.maps.Marker({
            position: location,
            map: this.map,
            shape: this.markerShape,
            icon: this.markerImage,
            title: title,
            zIndex: zIndex
        });
        this.bounds.extend(new google.maps.LatLng(location.lat, location.lng));
    };
    MelonMap.prototype.centerMap = function () {
        this.map.fitBounds(this.bounds);
    };
    /**
     * pan center of the map by given pixel value.
     * @param xAxis pan west/east
     * @param yAxis pan noth/south
     */
    MelonMap.prototype.panBy = function (xAxis, yAxis) {
        this.map.panBy(xAxis, yAxis);
    };
    return MelonMap;
}());
